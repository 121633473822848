<template>
    <div class="container">
        <crud :options="options"></crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            categories: ['菜单', '按钮'],
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: false,
                addUrl: 'admin.menu.add',
                addPerm: 'admin_menu_add',
                editUrl: 'admin.menu.edit',
                editPerm: 'admin_menu_edit',
                delUrl: 'admin.menu.delete',
                delPerm: 'admin_menu_delete',
                listUrl: 'admin.menu.lists',
                formLabelWidth: '100px',
                columns: [
                    {
                        prop: "parent_id",
                        label: "上级菜单",
                        width: 120,
                        hide: true,
                        type: 'cascader',
                        dicUrl: 'admin.menu.lists',
                        dicData: [],
                        dicProps: {label: 'name', value: 'id'}
                    },
                    {prop: "name", label: "菜单名称", width: 120, required: true},
                    {prop: "code", label: "菜单编码", width: 120, required: true},
                    {prop: "alias", label: "菜单别称", width: 120},
                    {prop: "path", label: "菜单路由", width: 120, required: true},
                    {
                        prop: "icon", label: "菜单图标", width: 60, html: ({row}) => {
                            return row.icon ? '<i class="' + row.icon + '"></i>' : ''
                        }
                    },
                    {
                        prop: "category",
                        label: "菜单类型",
                        width: 100,
                        type: 'radio',
                        value: 1,
                        dicData: [{label: '菜单类型', value: 1}, {label: '按钮类型', value: 2}],
                        formatter: (row) => {
                            return this.categories[row.category - 1]
                        }
                    },
                    {prop: "sort", label: "菜单排序", width: 100, value: 0, type: 'number'},
                    {prop: "remark", label: "菜单备注", width: 100},
                ]
            }
        }
    }
}
</script>

<style scoped>

</style>
